
<script setup>
    import colors from "mobile.colors.js";

    const props = defineProps(["to", "img", "ribbon"]);

    function onClick(e) {
        console.log(e);
    }
    
</script>

<template>
    <a class="afm-app-card" :href="props.to || '#'" @click="onClick">
        <div class="flex-shrink-0" v-if="props.ribbon" style="width: 5px; border-radius: 0.5rem 0 0 0.5rem;" :style="{ 'background-color': colors[props.ribbon].background }"></div>
        <div class="flex-grow-1" style="padding: 0.75rem;">
            <div class="d-flex">
                <div class="flex-grow-1">
                    <div style="color: rgb(40%, 40%, 40%); font-size: 0.7em; text-transform: uppercase; letter-spacing: 0.1em;">
                        <slot name="type" />
                    </div>
                    <div style="font-size: 0.9em; font-weight: 500;">
                        <slot name="title" />
                    </div>
                    <div class="d-flex" style="margin-top: 0.25rem; gap: 0.25rem;" v-if="$slots.pills">
                        <slot name="pills" />
                    </div>
                </div>
                <div class="flex-shrink-0" v-if="props.img && !props.img.endsWith('null')">
                    <img
                        class="bg-light border"
                        style="width: 3.5rem; height: 3.5rem; border-radius: 999px; object-fit: cover;"
                        :src="props.img"
                    />
                </div>
            </div>
            <div style="margin-top: 0.25rem; margin-bottom: -0.25rem; font-size: 0.8em;" v-if="$slots.bottom">
                <slot name="bottom"></slot>
            </div>
            <slot />
        </div>
    </a>
</template>

<!--
<style>
    .afm-app-card-pills {
        > div {
            display: inline-block;
            font-size: 0.7em;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            background-color: rgb(90%, 90%, 90%);
            color: black;
            padding: 0.25rem 0.5rem;
            border-radius: 999px;
        }
    }
</style>
-->

<style scoped>
    .afm-app-card {
        display: flex;
        position: relative;

        color: black;
        background-color: var(--bs-white);

        text-decoration: none;

        border-radius: 0.5rem;
        box-shadow: 2px 4px 8px rgba(0%, 0%, 0%, 5%);
        border: 1px solid var(--bs-gray-300);

        /* padding: 0.75rem; */

        transition: all 200ms ease-in-out;

        &:active {
            background-color: var(--bs-gray-200);
            transition: all 0ms;
        }
    }


    .afm-app-card:before {
        position: absolute;
        inset: 0;
        background-color: rgb(50%, 50%, 50%);
        opacity: 0%;
        transition: all 200ms ease-in-out;
    }
    .afm-app-card:hover:before {
        opacity: 100%;
    }
</style>
